import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import { Outlet } from 'react-router-dom';
import { TiArrowSyncOutline } from "react-icons/ti";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { TbWorldPin } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa6";
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Background from './components/background';
import logo from './assets/logo.png'
import Image from 'react-bootstrap/Image';
import './css/navbar.css';

export default function MainNavbar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation()

  const handleHamburgerClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isActive = (paths) => {
    for (const path of paths) {
      if (location.pathname.includes(path)) {
        if (location.pathname !== '/' && path === '/') {
          continue
        }
        return 'active';
      }
    }
    return '';
  };
  

  return (
    <div className={isCollapsed ? 'wrapper collapse' : 'wrapper'}>
      <Background>
        <Outlet />
      </Background>
      <div style={{ display:'flex',position:'relative' }}>
        <div className="outer-sidebar" >
          <div className="top_navbar">
            <div className="hamburger" onClick={handleHamburgerClick}>
              <div className="one"></div>
              <div className="two"></div>
              <div className="three"></div>
            </div>
            
          </div>
          <div className="sidebar">
            <Container>
              <Stack className='mt-4' gap={3} style={{ padding:'10px' }}>
                <Image src={logo} />
                <Link to={'/'} className={isActive(['/', '/subdomain', '/url']) + ' p-2'}>
                  <span className="icon"><TbWorldPin /></span>
                  <span className="title">Sub-domains</span>
                </Link>
                <Link to={'/summary'} className={isActive(['/summary']) + ' p-2'}>
                  <span className="icon"><TiArrowSyncOutline /></span>
                  <span className="title">Summary</span>
                </Link>
                <Link to={'/profile'} className={isActive(['/profile']) + ' p-2'}>
                  <span className="icon"><FaRegUser /></span>
                  <span className="title">Profile</span>
                </Link>
                <Link to={'/admins'} className={isActive(['/admins']) + ' p-2'}>
                  <span className="icon"><MdOutlineAdminPanelSettings /></span>
                  <span className="title">Admins</span>
                </Link>
              </Stack>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
