import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomCheckButton from './customCheckButton';
import { REACT_APP_BACKEND_URL } from '../dev_variables';

export default function SearchEngineUrlModal({ onSuccess, urlDetails, subdomain_type, show, handleShow, subdomain_id, toast  }) {
  const [searchEngineUrl, setSearchEngineUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [urlInfo, setUrlInfo] = useState()
  const [scrapeImmediately, setScrapeImmediately] = useState(true)
  const [subdomainInfo, setSubdomainInfo] = useState({ subdomain: '', location: '', type:subdomain_type })

  useEffect(() => {
    setUrlInfo({})
    setSearchEngineUrl('')
    if (urlDetails && urlDetails._id) {
      setUrlInfo(urlDetails)
      setSearchEngineUrl(urlDetails.search_engine_url)
    }

    const get_subdomain = async() => {
        const req = await fetch(`${REACT_APP_BACKEND_URL}/subdomains/${subdomain_id}`)
        const res = await req.json()
  
        if (res){
          setSubdomainInfo((prev) => ({
            ...prev,
            subdomain:res.subDomainURL,
          }))
        }
      }
      get_subdomain()

  }, [urlDetails, show])

  const addUrl = async() => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    var payload = { 
      subdomain_id:subdomain_id,
      subdomain_type:subdomain_type,
      subdomain:subdomainInfo.subdomain,
      search_engine_url:searchEngineUrl,
      status: true,
      scrape_status: true,
      scrape_status_text: "scraping urls",
      scrape_progress: 15,
      scrape_progress_color: "info",
      no_of_urls: 0
    }
    if (!scrapeImmediately) {
      payload['scrape_status'] = false
      payload['scrape_status_text'] = 'inactive'
      payload['scrape_progress_color'] = 'danger'
      payload['scrape_progress'] = 10
    }
    if (urlInfo && urlInfo._id){
      payload['search_engine_url_id'] = urlInfo._id
      payload['status'] = urlInfo.status
      payload['scrape_status'] = urlInfo.scrape_status
      payload['scrape_status_text'] = urlInfo.scrape_status_text
      payload['scrape_progress_color'] = urlInfo.scrape_progress_color
      payload['scrape_progress'] = urlInfo.scrape_progress
      payload['no_of_urls'] = urlInfo.no_of_urls
    }

    var operation = 'add'
    if (urlInfo && urlInfo._id){
      operation = 'edit'
    }
    var req_url = `${REACT_APP_BACKEND_URL}/search_engine_url/${operation}`
    const req = await fetch(req_url , {
        method: 'POST',
        body: JSON.stringify(payload),
        headers
    })
    const {status, result} = await req.json()
    setIsLoading(false)

    if (status) {
      toast.success(`URL ${urlInfo && urlInfo._id ? 'updated' : 'added'} successfully.`)
      onSuccess(operation, result)
      handleShow()
    }else if(!status){
      toast.error(result)
    }
  }

  const validateUrl = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    if (!searchEngineUrl.startsWith('https://')) {
      setIsLoading(false)
      toast.error('Invalid URL Format | Missing https://')
      return
    }
    console.log("searchEngineUrl ==>", searchEngineUrl)
    var req = await fetch(`${REACT_APP_BACKEND_URL}/verifyurl?url=${searchEngineUrl}`)
    var {valid, err} = await req.json()
    console.log("valid ==>", valid)
    
    if(valid){
      return addUrl()
    }
    toast.error(err)
    setIsLoading(false)
  }


  return (
    <Modal show={show} onHide={handleShow}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{urlInfo && urlInfo._id ? 'Edit' : 'Add'} a Search Engine URL</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validateUrl}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Search Engine URL</Form.Label>
            <Form.Control
              type="url"
              placeholder="https://www.xxxx.com"
              required
              value={searchEngineUrl}
              onChange={(e) => setSearchEngineUrl(e.target.value)}
            />
          </Form.Group>
          {
            (!urlInfo || !urlInfo._id) &&
            <Form.Group
              className="my-4"
              controlId="exampleForm.ControlTextarea1"
            >
              <CustomCheckButton checkedState={scrapeImmediately} onClickFunc={() => setScrapeImmediately(!scrapeImmediately)}/>
              <span className='ms-2 justify-self-center'>scrape immediately</span>
            </Form.Group>
          }
          <Modal.Footer>
            <Button disabled={isLoading} variant="secondary" onClick={isLoading ? null : handleShow}>
              Close
            </Button>
            <Button type='submit' disabled={isLoading || searchEngineUrl.length < 1} variant="success">
                {urlInfo && urlInfo._id ? 'Update' : 'Add'} URL
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
