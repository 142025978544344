import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import Login from './login';
import Main from './main';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainNavbar from './navbar';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store';
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider
} from "react-router-dom";
import Summary from './summary';
import Profile from './profile';
import UrlsPage, { Loader as UrlLoader } from './urlsPage';
import ScrapeInfoPage, { Loader as ScrapeInfoPageLoader } from './scrapeInfoPage';
import Admin from './admin';
import { verifyAuthToken, log_in_admin } from './utils/auth';
import { REACT_APP_BACKEND_URL } from './dev_variables';
import { authAction } from './store/actions/actions';
import LoaderPage from './loader';
import SearchEngineUrls, { Loader as SearchEngineUrlsLoader } from './searchEngineUrls';

function AppRouter() {
  const authId = useSelector(state => state.auth.id);
  const [isLoading, setIsLoading] = useState(true)
  // const dispatch = useDispatch()
  // const loggedInToken = verifyAuthToken()

  useEffect(() => {
    // const showLoader = async() => {
    //   function sleep (time) {
    //     return new Promise((resolve) => setTimeout(resolve, time));
    //   }
    //   setIsLoading(true)
    //   await sleep(500)
    //   setIsLoading(false)
    // }
    // showLoader()
  }, [authId])
  
  const setIsLoaded = () => {
    setIsLoading(false)
  }

  // useEffect(() => {
  //   const checkIfLoggedInToken = () => {
  //     if (!loggedInToken) {
  //       console.log('TOKEN IS EXPIRED....')
  //       return <Navigate to='/'/>
  //       return redirect('/')
  //     }
  //   }
  //   checkIfLoggedInToken()
  // }, [loggedInToken])
  
  // if (!loggedInToken) {
  //   console.log('TOKEN IS EXPIRED....');
  //   return <Navigate to="/" />;
  // }
  

  

  // if (!authId && loggedInToken) {
  //   log_in_admin()
  // }


  const router = createBrowserRouter([
    isLoading
    ?
    {
      path: '*', element: <LoaderPage setIsLoaded={setIsLoaded} />, loader: log_in_admin
    }
    :
    authId
    ? {
        path: '/', element: <MainNavbar />,
        children: [
          { path: '/', element: <App /> },
          { path: '/subdomain/:subdomain_id/type/:type', element: <SearchEngineUrls />},
          { path: '/subdomain/:subdomain_id/type/:type/search_engine_url/:search_engine_url_id/urls', element: <UrlsPage /> },
          { path: '/subdomain/:subdomain_id/type/:type/search_engine_url/:search_engine_url_id/urls/:url_id', element: <ScrapeInfoPage /> },
          { path: '/summary', element: <Summary /> },
          { path: '/profile', element: <Profile /> },
          { path: '/admins', element: <Admin /> }
        ]
      }
    : {
        path: '/', element: <Main />,
        children: [
          { path: '/', element: <Login /> }
        ]
      }
  ]);

  return <RouterProvider router={router} />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>
);
